#offcanvasBottom{
    height: 35vh !important;
    width: calc(100vw - var(--ct-leftbar-width)) !important;
    margin-left: var(--ct-leftbar-width) !important;
}

.leftside-menu{
    z-index: 1001;
}

@media screen and (min-width: 768px) {
    #offcanvasRight {
        width: 40vw !important;
    }
}

@media screen and (max-width: 767px) {
    #offcanvasBottom.offcanvas {
        width: 100vw !important;
        margin-left: 0 !important;
    }
}

@media screen and (max-width: 1140px){
    #offcanvasBottom{
        width: calc(100vw - var(--ct-leftbar-width-sm)) !important;
        margin-left: var(--ct-leftbar-width-sm) !important;
    }
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 70vw !important;
    }
}