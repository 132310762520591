.leaflet-container {
    height: 92vh;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
}

#map {
    height: 100%;
}

.content-page {
    padding: 0;
}

.flash-message {
    position: absolute;
    top: 3px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #dc3545;
    border: 1px solid #dc3545;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    animation: fadeOut 7s forwards;
    z-index: 9990;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        display: none;
    }
}

.leaflet-container .leaflet-marker-pane img{
    left: -1rem;
}

.leaflet-top{
    z-index: 999 !important;
}