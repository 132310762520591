.react-datepicker-popper {
    left: -27px !important;
}

.react-datepicker__input-container>input {
    color: var(--ct-menu-item-color);
    background: var(--ct-menu-bg) !important;
    border: none;
}

.react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
    display: none;
}

#datepicker:hover {
    cursor: pointer;
}

@media screen and (max-width: 1140px){
    .help-lg{
        display: none;
    }

    .help-sm{
        display: flex;
    }
}

@media screen and (min-width: 1141px){
    .help-lg {
            display: flex;
        }
    
    .help-sm {
        display: none;
    }
}