.list-group-item{
    /* color: var(--ct-list-group-bg) !important; */
    background-color: rgba(var(--ct-dark-rgb), var(--ct-bg-opacity)) !important;
    border: none;
}

.accordion{
    --ct-accordion-bg: rgba(255, 255, 255, 0.075) !important;
}

.accordion-button{
    color: white;
}

.accordion-button:not(.collapsed){
    background-color: rgba(255, 255, 255, 0.075) !important;
    color: var(--ct-accordion-active-color);
}